import React from 'react';
import SearchButton from '../SearchButton/SearchButton';
import './PageLayout.css';

function PageLayout({ title, children }) {
  return (
    <div className="page-layout">
      <div className="page-header">
        {title && <h1 className="page-title">{title}</h1>}
        <SearchButton />
      </div>
      <div className="page-content">
        {children}
      </div>
    </div>
  );
}

export default PageLayout; 