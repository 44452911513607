import { projects } from './projects';
import { experiences } from './experiences';
import { skillSections } from './skills';

// Combine all content with searchable metadata
export const siteContent = {
  projects: projects.map(project => ({
    ...project,
    searchableContent: [
      project.title,
      project.description,
      ...(project.features || []),
      ...(project.tech || []),
      ...(project.tags || [])
    ].join(' '),
    searchPriority: 2,
    type: 'PROJECT'
  })),
  
  experiences: experiences.map(exp => ({
    ...exp,
    searchableContent: [
      exp.companyName,
      ...exp.roles.flatMap(role => [
        role.role,
        role.location,
        ...(role.bulletPoints || []),
        ...(role.skills || []),
        ...(role.highlights || [])
      ])
    ].join(' '),
    searchPriority: 1,
    type: 'EXPERIENCE',
    skills: exp.roles.flatMap(role => role.skills || [])
  })),

  skills: skillSections.flatMap(section => 
    section.skills.map(skill => ({
      ...skill,
      searchableContent: [
        skill.iconName,
        skill.description,
        section.title,
        skill.category,
        ...(skill.relatedTech || []),
        ...(skill.keywords || [])
      ].join(' '),
      searchPriority: 0,
      type: 'SKILL',
      path: '/skills'
    }))
  )
};

// Helper function to get element ID for search navigation
export const getSearchElementId = (item) => {
  switch (item.type) {
    case 'PROJECT':
      return `project-${item.id}`;
    case 'EXPERIENCE':
      return `experience-${item.id}`;
    case 'SKILL':
      return `skill-${item.iconName}`;
    default:
      return item.id;
  }
}; 