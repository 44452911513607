import React from 'react';
import PageLayout from '../components/PageLayout/PageLayout';
import SocialLinks from '../components/SocialLinks/SocialLinks';
import SectionBox from '../components/SectionBox/SectionBox';
import './Home.css';

function Home() {
  return (
    <PageLayout title="Welcome">
      <div className="home-content">
        <div className="profile-section">
          <img
            src="Profile.jpeg"
            alt="Jay's Profile"
            className="profile-image"
          />
          <div className="profile-text">
            <SectionBox 
              variant="highlight"
              title="Hi I'm Jay!"
              text="I am a third year Computer Science and Business Administration student, currently on Co-Op at Glaukos as a Software Engineer."
            />
          </div>
        </div>

        <SectionBox
          title="Links"
          variant="transparent"
        >
          <div className="links-container">
            <SocialLinks />
          </div>
        </SectionBox>
      </div>
    </PageLayout>
  );
}

export default Home;
