import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useSearch } from '../../context/SearchContext';
import './SearchButton.css';

const SearchButton = () => {
  const { setIsOpen } = useSearch();

  return (
    <button 
      className="header-search-button"
      onClick={() => setIsOpen(true)}
      aria-label="Search (⌘K)"
    >
      <FaSearch className="search-icon" />
      <span className="shortcut">⌘K</span>
    </button>
  );
};

export default SearchButton; 