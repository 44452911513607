import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';
import { siteContent } from '../data/content/content';

const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [registeredItems, setRegisteredItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const registerResult = useCallback((result) => {
    if (!result) return;
    setRegisteredItems(prev => {
      const filtered = prev.filter(r => r.id !== result.id);
      return [...filtered, result];
    });
  }, []);

  const unregisterResult = useCallback((id) => {
    if (!id) return;
    setRegisteredItems(prev => prev.filter(r => r.id !== id));
  }, []);

  const clearSearch = useCallback(() => {
    setSearchTerm('');
    setSearchResults([]);
    setIsOpen(false);
  }, []);

  const navigateToResult = useCallback((result) => {
    if (!result?.path) return;
    
    const elementId = result.elementId || (() => {
      switch (result.type) {
        case 'SKILL':
          return `skill-${result.title.toLowerCase().replace(/[^a-z0-9-]/g, '-')}`;
        default:
          return `${result.type.toLowerCase()}-${result.id}`.replace(/[^a-z0-9-]/g, '-');
      }
    })();

    setIsOpen(false);
    
    if (location.pathname !== result.path) {
      navigate(result.path);
      setTimeout(() => {
        const element = document.getElementById(elementId);
        if (element) {
          const headerOffset = 80;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });

          element.classList.add('search-highlight');
          setTimeout(() => {
            element.classList.remove('search-highlight');
          }, 2000);
        }
      }, 100);
    } else {
      const element = document.getElementById(elementId);
      if (element) {
        const headerOffset = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });

        element.classList.add('search-highlight');
        setTimeout(() => {
          element.classList.remove('search-highlight');
        }, 2000);
      }
    }

    clearSearch();
  }, [navigate, location.pathname, clearSearch, setIsOpen]);

  // Initialize Fuse.js with all content
  const fuseSearch = useMemo(() => {
    const searchableItems = [
      ...siteContent.projects,
      ...siteContent.experiences,
      ...siteContent.skills
    ];

    return new Fuse(searchableItems, {
      keys: [
        { name: 'title', weight: 0.4 },
        { name: 'description', weight: 0.3 },
        { name: 'searchableContent', weight: 0.3 },
        { name: 'tags', weight: 0.2 },
        { name: 'skills', weight: 0.2 },
        { name: 'bulletPoints', weight: 0.2 }
      ],
      threshold: 0.3,
      includeScore: true,
      shouldSort: true,
      useExtendedSearch: true,
      ignoreLocation: true,
      minMatchCharLength: 2
    });
  }, []);

  const handleSearch = useCallback((term) => {
    if (!term.trim()) {
      setSearchResults([]);
      return;
    }

    const results = fuseSearch.search(term)
      .map(({ item }) => {
        const type = item.type.toUpperCase();
        const id = item.id || item.iconName;
        
        return {
          ...item,
          id,
          type,
          title: item.title || item.iconName || item.companyName,
          subtitle: item.description || item.role,
          tags: item.tags || item.tech || item.skills || [],
          path: (() => {
            switch (type) {
              case 'PROJECT':
                return `/projects`;
              case 'EXPERIENCE':
                return `/resume`;
              case 'SKILL':
                return `/skills`;
              default:
                return '/';
            }
          })(),
          elementId: (() => {
            switch (type) {
              case 'PROJECT':
                return `project-${id}`;
              case 'EXPERIENCE':
                return `experience-${id}`;
              case 'SKILL':
                return `skill-${item.iconName || id}`;
              default:
                return id;
            }
          })()
        };
      })
      .slice(0, 8);

    setSearchResults(results);
  }, [fuseSearch]);

  const value = useMemo(() => ({
    searchTerm,
    setSearchTerm,
    isOpen,
    setIsOpen,
    searchResults,
    registerResult,
    unregisterResult,
    clearSearch,
    navigateToResult,
    handleSearch
  }), [
    searchTerm,
    isOpen,
    searchResults,
    registerResult,
    unregisterResult,
    clearSearch,
    navigateToResult,
    handleSearch
  ]);

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
}; 