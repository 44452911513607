export const projects = [
  {
    id: 'pigeoner',
    title: "Pigeoner",
    image: "ProjectImages/Pigeoner.png",
    description: "A social networking Android app for broadcasting messages with real-time updates and user interactions",
    tech: ["Java", "Android Studio", "Google Firebase", "Android SDK"],
    youtube: "https://www.youtube.com/watch?v=cchw-jIEsAI",
    github: "https://github.com/Jay-Atal/Pigeoner",
    features: [
      "Real-time message broadcasting",
      "Like and reply functionality",
      "User follow system",
      "Profile picture management",
      "Bio updates",
      "Push notifications",
      "Real-time Firebase integration"
    ],
    path: "/projects",
    type: "PROJECT",
    tags: ["Android", "Social Network", "Mobile App", "Firebase"]
  },
  {
    id: 'flight-data-analysis',
    title: "Flight Data Analysis",
    description: "MongoDB & Data Science project analyzing flight delays and patterns",
    tech: ["MongoDB", "PyMongo", "Python", "Matplotlib", "Data Analysis"],
    features: [
      "Inserted and queried Flight data from JSON into MongoDB",
      "Data visualization with Matplotlib",
      "Analysis of delay causes and patterns",
      "Airport delay statistics"
    ],
    image: "ProjectImages/FlightDataAnalysis.png",
    github: "https://github.com/Jay-Atal/PlaneAnalysis",
    path: "/projects",
    type: "PROJECT",
    tags: ["MongoDB", "Python", "Data Science", "Analysis"]
  },
  {
    id: 'dreamworks-coop-tool',
    title: "DreamWorks Co-Op Search Tool",
    description: "A full-stack application for co-op experience reviews using MySQL and Flask",
    github: "https://github.com/Jay-Atal/DreamWorks",
    youtube: "https://youtu.be/5HKpSLmYiLo",
    image: "ProjectImages/Dreamworks.png",
    tech: ["MySQL", "Flask", "Python", "REST API", "Frontend"],
    features: [
      "Built MySQL database for co-op reviews",
      "Implemented REST API with Flask",
      "Designed functional frontend",
      "Data modification capabilities"
    ],
    path: "/projects",
    type: "PROJECT",
    tags: ["MySQL", "Flask", "Python", "Web Development"]
  },
  {
    id: 'cricket-analysis',
    title: "Cricket Analysis",
    description: "Web scraping and regression analysis of cricket player performance factors",
    image: "ProjectImages/CricketAnalysis.png",
    github: "https://github.com/Jay-Atal/Cricket-Data-Analysis",
    tech: ["Beautiful Soup", "Scikit-Learn", "Pandas", "Python"],
    features: [
      "Web scraping with Beautiful Soup",
      "Data analysis with Pandas",
      "Multi-regression modeling",
      "Player performance analysis"
    ],
    path: "/projects",
    type: "PROJECT",
    tags: ["Data Science", "Web Scraping", "Machine Learning"]
  },
  {
    id: 'java-journal',
    title: "Jay's Java Journal",
    description: "A weekly planner application built with Java and JavaFX using MVC principles",
    tech: ["Java", "JavaFX", "Object-Oriented Programming"],
    features: [
      "MVC architecture implementation",
      "UI Design and theming",
      "Backend integration",
      "Splash screen",
      "Summary statistics"
    ],
    image: "ProjectImages/JaysJavaJournal.png",
    github: "https://github.com/Jay-Atal/TaskManager-JaysJavaJournal",
    path: "/projects",
    type: "PROJECT",
    tags: ["Java", "Desktop App", "Task Management"]
  },
  {
    id: 'spin-up-scorer',
    title: "Spin Up Scorer",
    description: "iOS app for scoring robotics matches, published on the Apple App Store with 750+ downloads",
    tech: ["Swift", "Xcode", "iOS SDK"],
    features: [
      "Published on Apple App Store",
      "750+ user downloads",
      "Robotics match scoring system",
      "iOS native development"
    ],
    image: "ProjectImages/SpinUpScorer.png",
    github: "https://github.com/Jay-Atal/SpinUpScorer",
    path: "/projects",
    type: "PROJECT",
    tags: ["iOS", "Swift", "Mobile App", "App Store"]
  }
];