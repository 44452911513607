import React from 'react';
import './SectionBox.css';

function SectionBox({ title, text, variant = 'default', children }) {
  return (
    <div className={`section-box ${variant}`}>
      {title && <h2>{title}</h2>}
      {text && <p>{text}</p>}
      {children}
    </div>
  );
}

export default SectionBox; 