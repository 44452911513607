import {
  SiPython,
  SiKotlin,
  SiSwift,
  SiCplusplus,
  SiMysql,
  SiMongodb,
  SiFlask,
  SiGit,
} from "react-icons/si";
import { FaJava, FaDatabase } from "react-icons/fa";
import { DiRedis } from "react-icons/di";

export const skillSections = [
  {
    title: "Languages",
    skills: [
      { icon: SiPython, iconName: "Python", category: "Language", iconColor: "#4584b6" },
      { icon: FaJava, iconName: "Java", category: "Language", iconColor: "#f89820" },
      { icon: SiKotlin, iconName: "Kotlin", category: "Language", iconColor: "#B125EA" },
      { icon: SiSwift, iconName: "Swift", category: "Language", iconColor: "#f05138" },
      { icon: SiCplusplus, iconName: "C++", category: "Language", iconColor: "#00549D" },
    ]
  },
  {
    title: "Databases",
    skills: [
      { icon: SiMysql, iconName: "MySQL", category: "Database", iconColor: "#00758f" },
      { icon: SiMongodb, iconName: "MongoDB", category: "Database", iconColor: "#4DB33D" },
      { icon: DiRedis, iconName: "Redis", category: "Database", iconColor: "#D82C20" },
      { icon: FaDatabase, iconName: "Neo4j", category: "Database", iconColor: "#0164a1" },
    ]
  }
];

export const skills = skillSections; 