import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { SearchProvider } from './context/SearchContext';
import './styles/globals.css';
import Home from './pages/Home';
import Resume from './pages/Resume';
import Skills from './pages/Skills';
import Projects from './pages/Projects';
import Navbar from './components/Navbar/Navbar';
import SearchBar from './components/SearchBar/SearchBar';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import ExternalRedirect from './components/ExternalRedirect/ExternalRedirect';

// Separate the content that needs access to router hooks
function AppContent() {
  return (
    <SearchProvider>
      <Navbar />
      <SearchBar />
      <main className="main-content">
        <Outlet />
      </main>
    </SearchProvider>
  );
}

// Create router configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: <AppContent />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "resume",
        element: <Resume />
      },
      {
        path: "skills",
        element: <Skills />
      },
      {
        path: "projects",
        children: [
          {
            index: true,
            element: <Projects />
          },
          {
            path: ":projectId",
            element: <Projects />
          }
        ]
      },
      {
        // Handle external links
        path: "external/:url",
        element: <ExternalRedirect />
      },
      {
        // Catch all unmatched routes and redirect to home
        path: "*",
        element: <Navigate to="/" replace />
      }
    ]
  }
], {
  future: {
    v7_startTransition: true,
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true
  }
});

// Main App component
function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;

