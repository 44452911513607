import React from 'react';
import { FaLinkedin, FaGithub, FaYoutube } from 'react-icons/fa';
import './SocialLinks.css';

const SocialLinks = () => {
  const socialLinks = [
    {
      icon: <FaLinkedin />,
      url: 'https://www.linkedin.com/in/jay-atal/',
      label: 'LinkedIn',
      platform: 'LinkedIn'
    },
    {
      icon: <FaGithub />,
      url: 'https://github.com/Jay-Atal',
      label: 'GitHub',
      platform: 'GitHub'
    },
    {
      icon: <FaYoutube />,
      url: 'https://www.youtube.com/@JayFlyerVids',
      label: 'YouTube',
      platform: 'YouTube'
    }
  ];

  return (
    <div className="social-links">
      {socialLinks.map((link, index) => (
        <a
          key={index}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="social-link"
        >
          {link.icon}
          <span className="social-text">{link.platform}</span>
        </a>
      ))}
    </div>
  );
};

export default SocialLinks; 