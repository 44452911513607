import React from 'react';
import { useParams } from 'react-router-dom';
import { SearchableComponent } from '../components/Searchable/SearchableComponent';
import PageLayout from '../components/PageLayout/PageLayout';
import { useSearch } from '../context/SearchContext';
import {
  SiPython, SiKotlin, SiSwift, SiCplusplus,
  SiMysql, SiMongodb, SiFlask, SiGit,
  SiAndroidstudio, SiFirebase, SiIntellijidea,
  SiJunit5, SiXcode, SiPandas, SiScikitlearn,
  SiSelenium
} from "react-icons/si";
import { FaJava } from "react-icons/fa";
import { DiRedis } from "react-icons/di";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import './Skills.css';

const skillSections = [
  {
    title: "Languages",
    skills: [
      { icon: SiPython, iconName: "Python", iconColor: "#4584b6" },
      { icon: FaJava, iconName: "Java", iconColor: "#f89820" },
      { icon: SiKotlin, iconName: "Kotlin", iconColor: "#B125EA" },
      { icon: SiSwift, iconName: "Swift", iconColor: "#f05138" },
      { icon: SiCplusplus, iconName: "C++", iconColor: "#00549D" },
    ]
  },
  {
    title: "Databases",
    skills: [
      { icon: SiMysql, iconName: "MySQL", iconColor: "#00758f" },
      { icon: SiMongodb, iconName: "MongoDB", iconColor: "#4DB33D" },
      { icon: DiRedis, iconName: "Redis", iconColor: "#D82C20" },
      { icon: SiFirebase, iconName: "Google Firebase", iconColor: "#FFA611" },
    ]
  },
  {
    title: "Frameworks, Libraries, General",
    skills: [
      { icon: AiFillApple, iconName: "iOS", iconColor: "var(--text-primary)" },
      { icon: AiFillAndroid, iconName: "Android", iconColor: "#A4C639" },
      { icon: SiFlask, iconName: "Flask", iconColor: "#1b47c4" },
      { icon: SiPandas, iconName: "Pandas", iconColor: "#4B8BBE" },
      { icon: SiScikitlearn, iconName: "Sci Kit Learn", iconColor: "#e59326" },
      { icon: SiGit, iconName: "Git", iconColor: "var(--text-secondary)" },
      { icon: SiAndroidstudio, iconName: "Android Studio", iconColor: "var(--text-primary)" },
      { icon: SiIntellijidea, iconName: "IntelliJ", iconColor: "#FF8C00" },
      { icon: SiXcode, iconName: "Xcode", iconColor: "#1875df" },
      { icon: SiJunit5, iconName: "JUnit 5", iconColor: "#dc514a" },
      { icon: SiSelenium, iconName: "Selenium", iconColor: "#42b029" },
    ]
  }
];

function Skills() {
  const { skillId } = useParams();
  const { setSearchTerm, setIsOpen, handleSearch } = useSearch();
  
  const handleSkillClick = (skillName) => {
    setIsOpen(true);
    setSearchTerm(skillName);
    handleSearch(skillName);
  };
  
  return (
    <PageLayout title="Skills">
      <div className="skills-content">
        {skillSections.map((section) => (
          <div key={section.title} className="skill-section">
            <h2 className="section-title">{section.title}</h2>
            <div className="skill-grid">
              {section.skills.map((skill) => (
                <SearchableComponent
                  key={skill.iconName}
                  id={skill.iconName}
                  type="SKILL"
                  title={skill.iconName}
                  subtitle={section.title}
                  tags={[skill.iconName, section.title]}
                  path={`/skills/${skill.iconName}`}
                >
                  <div 
                    className="skill-card"
                    onClick={() => handleSkillClick(skill.iconName)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        handleSkillClick(skill.iconName);
                      }
                    }}
                  >
                    <div className="skill-icon-wrapper" style={{ color: skill.iconColor }}>
                      <skill.icon className="skill-icon" />
                    </div>
                    <div className="skill-content">
                      <h3 className="skill-name">{skill.iconName}</h3>
                    </div>
                  </div>
                </SearchableComponent>
              ))}
            </div>
          </div>
        ))}
      </div>
    </PageLayout>
  );
}

export default Skills;

