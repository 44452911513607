import React, { useCallback, useEffect, useRef } from 'react';
import { useSearch } from '../../context/SearchContext';
import { FaSearch, FaTimes } from 'react-icons/fa';
import './SearchBar.css';

const SearchBar = () => {
  const { 
    searchTerm, 
    setSearchTerm, 
    isOpen, 
    setIsOpen,
    handleSearch,
    searchResults,
    navigateToResult,
    clearSearch
  } = useSearch();
  const inputRef = useRef(null);

  // Group results by type
  const groupedResults = searchResults.reduce((acc, result) => {
    const type = result.type || 'OTHER';
    if (!acc[type]) acc[type] = [];
    acc[type].push(result);
    return acc;
  }, {});

  // Define section order and labels
  const sections = [
    { key: 'EXPERIENCE', label: 'Experience' },
    { key: 'PROJECT', label: 'Projects' },
    { key: 'SKILL', label: 'Skills' }
  ];

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  }, [setSearchTerm, handleSearch]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        setIsOpen(true);
      } else if (e.key === 'Escape') {
        clearSearch();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [setIsOpen, clearSearch]);

  if (!isOpen) return null;

  return (
    <div className="search-overlay" onClick={() => clearSearch()}>
      <div className="search-modal" onClick={e => e.stopPropagation()}>
        <div className="search-input-wrapper">
          <FaSearch className="search-icon" />
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search projects, skills, and experience..."
            autoFocus
          />
          {searchTerm && (
            <button 
              className="clear-button"
              onClick={clearSearch}
              aria-label="Clear search"
            >
              <FaTimes />
            </button>
          )}
        </div>

        <div className="search-results">
          {searchTerm && searchResults.length === 0 ? (
            <div className="no-results">
              <p>No results found for "{searchTerm}"</p>
              <p>Try searching for something else</p>
            </div>
          ) : !searchTerm ? (
            <div className="no-results">
              <p>Start typing to search</p>
              <p>Search across projects, skills, and experience</p>
            </div>
          ) : (
            sections.map(({ key, label }) => (
              groupedResults[key]?.length > 0 && (
                <div key={key} className="search-section">
                  <div className="search-section-header">
                    {label}
                  </div>
                  {groupedResults[key].map((result) => (
                    <button
                      key={result.id}
                      className="search-result"
                      onClick={() => navigateToResult(result)}
                    >
                      <div className="result-content">
                        <div className="result-title">
                          {result.title}
                        </div>
                        {result.subtitle && (
                          <div className="result-subtitle">
                            {result.subtitle}
                          </div>
                        )}
                        {result.tags && result.tags.length > 0 && (
                          <div className="result-tags">
                            {result.tags.slice(0, 3).map(tag => (
                              <span key={tag} className="tag">{tag}</span>
                            ))}
                          </div>
                        )}
                      </div>
                    </button>
                  ))}
                </div>
              )
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar; 