import React from 'react';
import { useParams } from 'react-router-dom';
import { SearchableComponent } from '../components/Searchable/SearchableComponent';
import PageLayout from '../components/PageLayout/PageLayout';
import { projects } from '../data/content';
import { FaGithub, FaExternalLinkAlt, FaYoutube } from 'react-icons/fa';
import './Projects.css';

function Projects() {
  const { projectId } = useParams();
  const projectsToShow = projectId 
    ? projects.filter(p => p.id === projectId)
    : projects;

  return (
    <PageLayout title="Projects">
      <div className="projects-grid">
        {projectsToShow.map(project => (
          <SearchableComponent
            key={project.id}
            id={project.id}
            type="PROJECT"
            title={project.title}
            subtitle={project.description}
            tags={[...project.tech, project.title]}
            description={project.description}
            path={`/projects/${project.id}`}
          >
            <div className="project-card">
              <div className="project-header">
                <h3>{project.title}</h3>
                <div className="project-actions">
                  {project.github && (
                    <a
                      href={project.github}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(project.github, '_blank', 'noopener,noreferrer');
                      }}
                      className="project-action"
                      aria-label="View on GitHub"
                    >
                      <FaGithub />
                    </a>
                  )}
                  {project.youtube && (
                    <a
                      href={project.youtube}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(project.youtube, '_blank', 'noopener,noreferrer');
                      }}
                      className="project-action"
                      aria-label="Watch Demo"
                    >
                      <FaYoutube />
                    </a>
                  )}
                  {project.demo && (
                    <a
                      href={project.demo}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(project.demo, '_blank', 'noopener,noreferrer');
                      }}
                      className="project-action"
                      aria-label="View Demo"
                    >
                      <FaExternalLinkAlt />
                    </a>
                  )}
                </div>
              </div>

              <div className="project-preview">
                {project.image && (
                  <img 
                    src={project.image} 
                    alt={project.title}
                    className="project-image"
                  />
                )}
              </div>

              <div className="project-content">
                <p>{project.description}</p>
                
                <div className="project-tech">
                  {project.tech.map((tech, index) => (
                    <span key={index} className="tech-tag">
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </SearchableComponent>
        ))}
      </div>
    </PageLayout>
  );
}

export default Projects;
