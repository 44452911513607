import React, { useEffect, useMemo, useRef } from 'react';
import { useSearch } from '../../context/SearchContext';
import './SearchableComponent.css';

export const SearchableComponent = React.memo(function SearchableComponent({ 
  id,
  type,
  title,
  subtitle = '',
  tags = [],
  description = '',
  path,
  children 
}) {
  const { registerResult, unregisterResult } = useSearch();
  const elementRef = useRef(null);
  
  const searchData = useMemo(() => ({
    id,
    type,
    title,
    subtitle,
    description,
    tags: Array.isArray(tags) ? tags : [],
    path,
    priority: type === 'EXPERIENCE' ? 3 : type === 'PROJECT' ? 2 : type === 'SKILL' ? 1 : 0
  }), [id, type, title, subtitle, description, tags, path]);

  useEffect(() => {
    if (!id || !type || !title) return;
    registerResult(searchData);
    return () => unregisterResult(id);
  }, [id, type, title, searchData, registerResult, unregisterResult]);

  return (
    <div 
      ref={elementRef}
      className="searchable-component"
      id={`${type.toLowerCase()}-${id}`.replace(/[^a-z0-9-]/g, '-')}
      data-type={type}
    >
      {children}
    </div>
  );
}); 