import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function ExternalRedirect() {
  const { url } = useParams();
  
  useEffect(() => {
    if (url) {
      const decodedUrl = decodeURIComponent(url);
      window.location.href = decodedUrl.startsWith('http') ? decodedUrl : `https://${decodedUrl}`;
    }
  }, [url]);

  return null;
} 