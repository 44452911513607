import React from 'react';
import './RoleBox.css';

function RoleBox({ logoUrl, companyName, roles = [], variant = 'default' }) {
  const rolesArray = Array.isArray(roles) ? roles : [roles];

  return (
    <div className={`role-box ${variant}`}>
      <div className="company-header">
        {logoUrl && (
          <div className="company-logo-wrapper">
            <img 
              src={logoUrl} 
              alt={`${companyName} logo`}
              className="company-logo"
            />
          </div>
        )}
        <div className="company-info">
          <h3 className="company-name">{companyName}</h3>
        </div>
      </div>
      
      {rolesArray.map((roleDetail, index) => (
        <div 
          key={index}
          className="role-section"
          id={`role-${companyName.toLowerCase().replace(/[^a-z0-9-]/g, '-')}-${index}`}
        >
          <h4 className="role-title">{roleDetail.role}</h4>
          <div className="role-meta">
            <span className="date-line">{roleDetail.dateLine}</span>
            {roleDetail.location && (
              <>
                <span className="location-dot">·</span>
                <span className="location">{roleDetail.location}</span>
              </>
            )}
          </div>

          {roleDetail.highlights?.length > 0 && (
            <div className="highlights">
              {roleDetail.highlights.map((highlight, i) => (
                <span key={i} className="highlight-tag">{highlight}</span>
              ))}
            </div>
          )}

          {roleDetail.bulletPoints?.length > 0 && (
            <ul className="bullet-points">
              {roleDetail.bulletPoints.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}

export default RoleBox;
