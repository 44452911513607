import React from 'react';
import PageLayout from '../components/PageLayout/PageLayout';
import { SearchableComponent } from '../components/Searchable/SearchableComponent';
import RoleBox from '../components/RoleBox/RoleBox';
import { experiences } from '../data/content';
import './Resume.css';

function Resume() {
  return (
    <PageLayout title="Experience">
      <div className="resume-content">
        <section className="experience-section">
          <div className="timeline">
            {experiences.map((experience, index) => (
              <SearchableComponent
                key={index}
                id={experience.id}
                type="EXPERIENCE"
                title={experience.companyName}
                subtitle={experience.roles[0].role}
                tags={experience.roles[0].skills}
                description={experience.description}
                path="/resume"
              >
                <RoleBox
                  logoUrl={experience.logoUrl}
                  companyName={experience.companyName}
                  roles={experience.roles}
                />
              </SearchableComponent>
            ))}
          </div>
        </section>
      </div>
    </PageLayout>
  );
}

export default Resume;
