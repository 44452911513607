import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorBoundary.css';

export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };
  
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  
  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <h1>Oops! Something went wrong</h1>
          <p>Don't worry, you can:</p>
          <button 
            onClick={() => window.location.href = '/'}
            className="error-button"
          >
            Go back home
          </button>
          <button 
            onClick={() => window.location.reload()}
            className="error-button secondary"
          >
            Try again
          </button>
        </div>
      );
    }
    
    return this.props.children;
  }
} 