export const experiences = [
  {
    id: 'glaukos-coop',
    companyName: 'Glaukos',
    logoUrl: 'Glaukos.jpeg',
    roles: [{
      role: 'Software Engineering Co-Op',
      dateLine: 'May 2024 - Dec 2024',
      location: 'Burlington, MA',
      bulletPoints: [],
      skills: ['Software Engineering', 'Co-Op', 'Glaukos', 'Python', 'ETL']
    }]
  },
  {
    id: 'khoury-ta',
    companyName: 'Khoury College of Computer Sciences',
    logoUrl: 'Khoury.jpeg',
    roles: [{
      role: 'Teaching Assistant',
      dateLine: 'Jan 2024 - Apr 2024',
      location: 'Boston, MA',
      highlights: ['Fundamentals of Computer Science 2 (CS 2510)'],
      bulletPoints: [
        'Host weekly office hours for students to debug java student code and clarify course content',
        'Grade student work and provide them with comments for feedback',
        "Timely and consistently answer students' questions on online forum Piazza"
      ],
      skills: ['Teaching', 'CS2510', 'Fundamentals of CS 2', 'Java']
    }]
  },
  {
    id: 'apple-sales',
    companyName: 'Apple',
    logoUrl: 'Apple.jpeg',
    roles: [{
      role: 'Sales Specialist',
      dateLine: 'Nov 2023 - Apr 2024',
      location: 'Dedham, MA',
      bulletPoints: [
        'Identify customer needs and provide personalized solutions',
        'Process transactions and set up customer devices',
        'Welcome customers as the first point of contact'
      ],
      skills: ['Sales', 'Apple', 'Customer Service']
    }]
  },
  {
    id: 'framingham-video',
    companyName: 'Framingham Public Schools',
    logoUrl: 'FraminghamPublicSchools.jpeg',
    roles: [
      {
        role: 'Video Production Instructor',
        dateLine: 'Jun 2021 - Aug 2023',
        location: 'Framingham, MA',
        highlights: ['Seasonal Position (Summers 2021-2023)'],
        bulletPoints: [
          'Taught Pre-Production / Production classes',
          'Guided students on news control room equipment',
          'Created professional videos for Framingham middle schools'
        ],
        skills: ['Video Production', 'Teaching', 'Framingham']
      },
      {
        role: 'Robotics Mentor',
        dateLine: 'Sep 2021 - Jun 2022',
        bulletPoints: [
          'Founding member of Robotics Club at Fuller Middle School',
          'Guided students in robotics and programming'
        ],
        skills: ['Robotics', 'Teaching', 'Programming']
      }
    ]
  }
];

export default experiences; 